import React from "react";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";
import modelData from "./alfalfa-weevil.json";
import GlobalStateContext from "../../context/globalStateContext";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import ManagementGuide from "../../components/models/managementGuide";
import ModelLayout from "../../components/models/modelLayout";
import { useAuth0 } from "../../context/react-auth0-spa";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function AlfalfaWeevil() {
  const h1Text = modelData.title;
  const { managementGuide } = modelData.elements;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, modelData.seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);
  let mData = null;
  if (data && station) {
    mData = modelLogic(modelData, data, dateOfInterest);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const isVisible = station && mData;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* Management Guide */}
      {isVisible && mData && mData.managementGuideMsg && showManagementGuide && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            {managementGuide.title}
          </h2>

          <ManagementGuide
            managementGuideMsg={mData.managementGuideMsg}
            explanation={managementGuide.explanation}
            setIsMoreInfo={setIsMoreInfo}
          ></ManagementGuide>
        </div>
      )}

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable station={station} data={mData.data}></ResultsTable>
        </div>
      )}
    </ModelLayout>
  );
}
