import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Harcourt, D.G. 1981. A thermal summation model for predicting seasonal
        occurence of the alfalfa weevil, Hypera postica
        (Coleoptera:Curculionidae), in southern Ontario. Canadian Entomologist.
        113: 601-605.
      </p>
    </>
  )
}
