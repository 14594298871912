import React from "react"

export default function MoreInfo({ moreInfo }) {
  return (
    <div>
      <h2 className="my-8">
        Degree days for peak (50%) occurrence of alfalfa weevil stages (Source:
        R.I. Carruthers)
      </h2>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Stage or Event
                    </th>
                    <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50">
                      Degree Days - Base 48 ˚F
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {moreInfo.map((d, i) => {
                    return (
                      <tr
                        key={i}
                        className={`${i % 2 === 0 ? `bg-white` : `bg-gray-50`}`}
                      >
                        <td className="px-6 py-4 text-sm font-medium leading-5 text-gray-900 whitespace-no-wrap">
                          {d.stage}
                        </td>
                        <td className="px-6 py-4 text-sm leading-5 text-gray-500 whitespace-no-wrap">
                          {d.dd}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
