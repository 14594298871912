import React from "react"

export default function Acknowledgments() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA alfalfa weevil tool was developed by Ken Wise, Jaime Cummings,
        and Keith Waldron (retired) at the New York State IPM Program, Cornell
        University.
      </p>
    </>
  )
}
